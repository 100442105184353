import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataTable } from "mantine-datatable";
import { setPageTitle } from "../store/themeConfigSlice";
import { AiFillEye } from "react-icons/ai";
import CustomPagination from "../components/Layouts/CustomPagination";
import { useNavigate } from "react-router-dom";
import IconSearch from "../components/Icon/IconSearch";
import IconXCircle from "../components/Icon/IconXCircle";
import DropdownCustom from "../components/DropdownCustom";
import UserService from "../service/user.service";
import Swal from "sweetalert2";

const status = [
  { label: "All", value: "" },
  { label: "Pending", value: "Pending" },
  { label: "Approve", value: "Approve" },
  { label: "Rejected", value: "Rejected" },
];

const UserTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
  }, [page, pageSize]);

  const handleChange = (type, page) => {
    const total = totalCount / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  const handleNavigate = (id) => {
    navigate(`/user/details/${id}`);
  };

  const getAllUser = async () => {
    try {
      const body = {
        search: searchText,
        status: selectedStatus,
        page: page,
        limit: pageSize,
      };
      setIsLoading(true);
      const response = await UserService.getAllUser(body);
      setData(response.data.data);
      setTotalCount(response.data.totalUser);
      console.log(":response.data.data", response.data.data);
    } catch (error) {
      showMessage(error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUser();
  }, [page, pageSize, searchText, selectedStatus]);

  const showMessage = (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  return (
    <div>
      <div className="panel">
        <div className="mb-4 flex gap-4">
          <div className="relative max-w-xs w-full">
            <input
              type="text"
              className="form-input pe-9 peer sm:bg-transparent bg-gray-100 placeholder:tracking-widest"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              type="button"
              className="absolute w-9 h-9 right-0 appearance-none peer-focus:text-primary"
            >
              <IconSearch className="mx-auto" />
            </button>
            <button
              type="button"
              className="hover:opacity-80 sm:hidden block absolute top-1/2 -translate-y-1/2 ltr:right-2 rtl:left-2"
              // onClick={() => setSearch(false)}
            >
              <IconXCircle />
            </button>
          </div>
          <DropdownCustom
            data={status}
            width={"100%"}
            name="Select Status"
            setData={setSelectedStatus}
          />
        </div>
        <div className="datatables user-table">
          <DataTable
            noRecordsText="No results match your search query"
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={data}
            columns={[
              {
                accessor: "id",
                title: "ID",
                // sortable: true,
                render: ({ id }, index) => (
                  <div>
                    <strong>{index + 1}</strong>
                  </div>
                ),
              },
              { accessor: "name", title: "Name" },
              {
                accessor: "acountTyp",
                title: "Acount Type",
                // sortable: true,
                render: ({ accountType }) => (
                  <div className="">
                    <span className="!text-xs font-bold">{accountType}</span>
                  </div>
                ),
              },
              {
                accessor: "email",
                // sortable: true,
                render: ({ email }) => (
                  <a href="mailto:denish.xrstudio@hmail.com">
                    <span>{email}</span>
                  </a>
                ),
              },
              // { accessor: "accountNo", title: "Acount No." },
              // { accessor: "bank", title: "Bank" },
              {
                accessor: "action",
                title: "Status Of Payyro",
                render: ({ statusOfPayyro }) => (
                  <div>
                    <span
                      className={`${
                        statusOfPayyro === "Approve"
                          ? "text-success bg-success/15"
                          : statusOfPayyro === "Pending"
                          ? "text-warning bg-warning/15"
                          : statusOfPayyro === "Rejected"
                          ? "text-danger bg-danger/15 cursor-pointer"
                          : ""
                      } rounded-full px-5 py-1.5 text-xs w-fit font-bold`}
                    >
                      {statusOfPayyro}
                    </span>
                  </div>
                ),
              },
              // {
              //   accessor: "action",
              //   title: "Status Of Document",
              //   render: ({ statusOfIncomeDocument }) => (
              //     <div>
              //       <span
              //         className={`${
              //           statusOfIncomeDocument === "Approve"
              //             ? "text-success bg-success/15"
              //             : statusOfIncomeDocument === "Pending"
              //             ? "text-warning bg-warning/15"
              //             : statusOfIncomeDocument === "Rejected"
              //             ? "text-danger bg-danger/15 cursor-pointer"
              //             : ""
              //         } rounded-full px-5 py-1.5 text-xs w-fit font-bold`}
              //       >
              //         {statusOfIncomeDocument}
              //       </span>
              //     </div>
              //   ),
              // },
              {
                accessor: "action",
                title: "Action",
                titleClassName: "!text-center",
                render: ({ _id }) => (
                  <div className="flex items-center justify-center mx-auto gap-2">
                    <div
                      onClick={() => handleNavigate(_id)}
                      className="cursor-pointer"
                    >
                      {/* <button className="flex items-center hover:text-info bg-primary/10 text-primary p-1 rounded">
                        <IconEye />
                      </button> */}
                      <AiFillEye className="h-6 w-6 text-primary/80" />
                    </div>
                  </div>
                ),
              },
            ]}
            totalRecords={totalCount}
            recordsPerPage={pageSize}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            minHeight={200}
            loading={isLoading}
            fetching={isLoading}
          />
          <CustomPagination
            total={totalCount}
            page={page}
            handleChange={handleChange}
            dataPerPage={pageSize}
            handleDataPerPage={handleDataPerPage}
            PAGE_SIZES={PAGE_SIZES}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTable;
